import React,{useEffect} from "react";
import styled from "styled-components";
import eliment from "../images/eliment.png";
import arrow2 from "../images/arrow2.png";
import semi from "../images/semi.png";
import diamond from "../images/diamond.png";
import AOS from "aos";
import "aos/dist/aos.css";
const Hero = () => {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);
  return (
    <HeroStyle>
      <img
        data-aos={"fade-up"}
        data-aos-delay={"500"}
        className="arrow"
        src={eliment}
        alt=""
      />
      <img data-aos={"fade-up"} className="arrow2" src={arrow2} alt="" />
      <img
        data-aos={"fade-top"}
        data-aos-delay={"1200"}
        className="diamond"
        src={diamond}
        alt=""
      />
      <img
        data-aos={"fade-top"}
        data-aos-delay={"1200"}
        className="semi"
        src={semi}
        alt=""
      />

      <small className="small">
        Qualified and interested candidates, At Scale
      </small>
      <div className="up">
        <h1>Hire in Hours,</h1>
        <h1>not days</h1>
      </div>
      <div className="down">
        <a href="mailto:career@keephiring.co">Start Hiring</a>
      </div>
    </HeroStyle>
  );
};
const HeroStyle = styled.div`
  @media (max-width: 700px) {
    overflow: hidden;
    margin-top: -8rem;
    .small {
      display: none;
    }
  }

  .arrow {
    position: absolute;
    top: 50%;
    left: 80%;
  }
  .arrow2 {
    position: absolute;
    top: 20%;
    left: 10%;
  }
  .diamond {
    position: absolute;
    top: 80%;
    left: 10%;
  }
  .semi {
    position: absolute;
    top: 20%;
    left: 80%;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 90vh;

  .small {
    border-bottom: 2px solid black;
  }

  .up {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    line-height: 8rem;
    font-size: 2.4rem;
    font-weight: 800;
    margin: 4rem 0rem 6rem 0rem;
    @media (max-width: 700px) {
      margin-top: -3rem;
      font-size: 1rem;
      line-height: 4rem;
    }
  }
  .down {
    a {
      background-color: black;
      text-decoration: none;
      color: white;
      padding: 1rem;
      font-size: 1.6rem;
      border-radius: 0.4rem;
    }
    margin-top: -2rem;
  }
  @media (max-width: 700px) {
    .arrow {
      left: 60%;
    }
    .diamond {
      top: 60%;
    }
    .semi {
      left: 88%;
    }
  }
  @media (max-width: 650px) {
    .up {
      margin-top: 2rem;
    }
    .arrow {
      left: 70%;
    }
    .arrow2 {
      left: 6%;
    }
  }
`;
export default Hero;
