import React from "react";
import logo from "../images/logo.png";
import { Link } from "react-router-dom";
import { AiFillFacebook } from "react-icons/ai";
import { FaTwitterSquare } from "react-icons/fa";
import { GrLinkedin } from "react-icons/gr";
import styled from "styled-components";

const Footer = ({ aboutus, contactus }) => {
  return (
    <FooterStyle>
      <div className="left">
        <img src={logo} alt="" />
        <div className="socials">
          <AiFillFacebook />
          <FaTwitterSquare />
          <a href=" https://www.linkedin.com/company/keep-hiring/" style={{"color":"black", "text-decoration":"none"}}><GrLinkedin /></a>
        </div>
      </div>
      <div className="solution">
        <h3>
          <u>Important Links</u>
        </h3>
        <ul>
          <a
            href="mailto:career@keephiring.co"
            target="_blank"
            rel="noreferrer"
            style={{ "text-decoration": "none", color: "black" }}
          >
            <li>Start Hiring</li>
          </a>
          <Link
            to="/about"
            onCLick={() => {
              aboutus.current.scrollIntoView({ behavior: "smooth" });
            }}
            style={{ "text-decoration": "none", color: "black" }}
          >
            {" "}
            <li>About Us</li>{" "}
          </Link>
          <Link
            to="/contact"
            onClick={() => {
              contactus.current.scrollIntoView({ behavior: "smooth" });
            }}
            style={{ "text-decoration": "none", color: "black" }}
          >
            {" "}
            <li>Contact Us</li>
          </Link>
        </ul>
      </div>
      <div className="solution">
        <h3>
          <u>Contact Us</u>
        </h3>
        <ul>
          <a
            href="mailto:career@keephiring.co"
            target="_blank"
            rel="noreferrer"
            style={{ "text-decoration": "none", color: "black" }}
          >
            <li>career@keephiring.co</li>
          </a>
          <a
            href="mailto:career@keephiring.co"
            target="_blank"
            rel="noreferrer"
            style={{ "text-decoration": "none", color: "black" }}
          >
            <li>Talk to an expert</li>
          </a>
          <a
            href="tel:+1 412-253-7334"
            style={{ "text-decoration": "none", color: "black" }}
          >
            <li>+1 412-253-7334</li>
          </a>
        </ul>
      </div>
    </FooterStyle>
  );
};



const FooterStyle = styled.div`
  background-color: #faf4ea;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  bottom: 0;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 2rem;
  .left {
    display: flex;
    margin-bottom: 30px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 2rem;
    * {
      margin: 0.4rem 0.4rem;
    }
  }
  .solution {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 20rem;
    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      padding: 0;
    }
    li {
      list-style: none;
      margin: 0.8rem;
    }
  }
`;
export default Footer;
