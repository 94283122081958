import React from 'react';
import InfiniteCarousel from 'react-leaf-carousel';
import slide1 from "../images/slide1.png";
import slide2 from "../images/slide2.png";
import slide3 from "../images/slide3.png";
import slide4 from "../images/slide4.png";
import styled from 'styled-components';

const Team=()=>{
    return (
        <TeamStyle>
          <div className="main">
        <div   className="team"><small><u>Team</u></small></div>
            <h1>Passionate Team</h1>
            <div className="text">Meet the people who have made it possible for several businesses to achieve their goals.</div>
            </div>
        <InfiniteCarousel className="slider"
        breakpoints={[
          {
            breakpoint: 500,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            },
          },
        ]}
        dots={true}
        showSides={true}
        sidesOpacity={0.5}
        sideSize={0.1}
        slidesToScroll={4}
        slidesToShow={4}
        scrollOnDevice={true}
      >
            
        <div>
          <img
            alt=""
            src={slide1}
          />
        </div>
        <div>
          <img
            alt=""
            src={slide2}
          />
        </div>
        <div>
          <img
            alt=""
            src={slide3}
          />
        </div>
        <div>
          <img
            alt=""
            src={slide4}
          />
        </div>
        <div>
          <img
            alt=""
            src={slide1}
          />
        </div>
        <div>
          <img
            alt=""
            src={slide2}
          />
        </div>
        <div>
          <img
            alt=""
            src={slide3}
          />
        </div>
        <div>
          <img
            alt=""
            src={slide4}
          />
        </div>
      </InfiniteCarousel>
      <div className="padding-div"></div>
      </TeamStyle>
    )
}
const TeamStyle=styled.div`

  .main{
    margin-top: -4rem;
  }
  .text{
    margin-bottom: 3rem;
  }
    h1,.team,.text{
        text-align: center;
    }
    h1{
        margin-top: 2rem;
        margin-bottom: 1rem;
    }
    .padding-div{
      padding-top:80px;
    }
    .InfiniteCarouselFrame{
      position: relative;
      &::before{
        content: "";
        background-color: #fbf5eb;
        width:50rem;
        height: 23rem;
        position: absolute;
        top:0%;
        right: 0%;
        border-radius: 5rem;
      }
      @media (max-width:768px){
        &::before{
          top:4%;
          width:20rem;
          height: 12rem;
        }
      }
    }
`
export default Team;