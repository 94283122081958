import React, { useEffect } from "react";
import styled from "styled-components";
import AOS from "aos";
import "aos/dist/aos.css";
import leader from "../images/leader.png";

const WorkWithLeaders = () => {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);
  return (
    <HeaderStyle>
      <div
        data-aos-delay={"100"}
        data-aos={"fade-up"}
        data-aos-duration={"1000"}
        className="right"
      >
        <img src={leader} alt="" />
      </div>
      <div
        data-aos-anchor-placement={"top-bottom"}
        data-aos={"fade-up"}
        data-aos-duration={"1000"}
        className="left"
      >
        <div className="hero-text">
          <small>Why partner with keep hiring</small>
          <div className="line"></div>
          <h1>Work with Leaders</h1>
          <h4>
            Keep Hiring not only selects qualified candidates but makes sure
            they have leadership qualities and can drive your business on the
            road of huge success.
          </h4>
        </div>

        <div className="down">
          People are the most valuable entity in a business. You not only need
          people who can get things done but also can take smart decisions for
          you. We only bring people who are leaders in their domains and have a
          capablity to grow your venture.
        </div>
        <a
          data-aos={"fade-up"}
          data-aos-duration={"800"}
          data-aos-delay={"400"}
          href="mailto:career@keephiring.co"
        >
          Talk to an expert
        </a>
      </div>
    </HeaderStyle>
  );
};
const HeaderStyle = styled.div`
  overflow: hidden;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 2rem;
  padding-top: 4rem;

  .left {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 50%;
    margin-left: 2rem;
    padding: 4rem;
    h4 {
      margin-bottom: -1rem;
    }
    h1 {
      margin-bottom: 1rem;
      margin-top: 1rem;
    }
    .down {
      line-height: 2rem;
    }

    a {
      text-decoration: none;
      color: white;
      background-color: black;
      margin-top: 2rem;
    }
  }
  .hero-text {
    font-size: 1rem;
    color: black;
    margin: -2rem 0rem 2rem 0rem;
  }
  a {
    background-color: white;
    text-decoration: none;
    border-radius: 10px;
    padding: 1.4rem;
    color: black;
    box-shadow: 0px 2px 4px gray;
    transition: 0.4s;
    &:hover {
      background-color: #383431;
      color: white;
    }
  }
  .right {
    width: 50%;
    position: relative;

    img {
      width: 28rem;
      height: 34rem;
      border-radius: 0.5rem;
      margin-left: 6rem;
      @media (max-width: 700px) {
        margin-top: 0rem;
        margin-left: -3rem;
      }
    }
    &::before {
      content: "";
      position: absolute;
      width: 34rem;
      height: 30rem;
      top: 20%;
      left: 8%;
      background-color: #feedce;
      z-index: -1;
      border-radius: 3rem;
      @media (max-width: 700px) {
        width: 22rem;
        height: 22rem;
        left: -14%;
        top: 28%;
      }
      @media (max-width: 650px) {
        width: 18rem;
        height: 16rem;
        left: -10%;
      }
    }
  }

  .line {
    width: 2rem;
    height: 0.2rem;
    background-color: black;
    margin-top: 0.3rem;
    border-radius: 0.4rem;
  }
  @media (max-width: 980px) {
    flex-wrap: wrap;
    .left {
      margin-top: 2rem;
    }
  }
  @media (max-width: 700px) {
    overflow-x: hidden;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding-right: 0;
    margin-top: -4rem;
    padding-top: 0;
    .left {
      margin-top: 4rem;
      flex-direction: column;
      h4 {
        max-width: 120%;
        width: 120%;
      }
      .hero-text,
      .down {
        margin-left: -4rem;
      }
      p {
        width: 40rem;
      }
      a {
        margin-left: -3rem;
      }
    }
    .right {
      margin-top: 2rem;
      margin-left: -10rem;
      img {
        width: 20rem;
        height: 28rem;
        margin-left: -8rem;
      }
    }
  }
  @media (max-width: 650px) {
    .right {
      img {
        width: 16rem;
        height: 20rem;
        margin-left: 0rem;
      }
    }
    .left {
      margin-left: -2rem;
    }
  }
`;
export default WorkWithLeaders;
