import './App.css';
import Nav from './components/Nav';

import Hero from './components/Hero';
import Mission from './components/Mission';
import HighVolume from './components/HighVolume';
import WorkWithLeaders from './components/WorkWithLeaders';
import Team from './components/Team';
import Work from './components/Work';
import Footer from './components/Footer';
import OurPartners from "./components/OurPartners";
import { useEffect, useRef } from 'react';
import { useLocation} from "react-router";
import {Route,Switch} from "react-router-dom";
import Scroll from './Scroll';

function App() {
  const aboutus=useRef(null);
  const contactus=useRef(null);
  // aboutus.current.scrollIntoView({behavior: "smooth"});
    const location=useLocation();
    const path=location.pathname;
  useEffect(()=>{
    if(path==="/contact"){
      contactus.current.scrollIntoView({behavior:"smooth"});
    }
    if(path==="/about"){
      aboutus.current.scrollIntoView({behavior: "smooth"});
    }
    console.log(path);

  },[path])
  
  return (
    <Switch>
    <div className="App">

    <Nav aboutus={aboutus} contactus={contactus}/>
    <Hero/>
    <div ref={aboutus} id="aboutus">
    <Route path={["/","/about"]} component={Mission}/>
    </div>
    <HighVolume/>
    <WorkWithLeaders/>
    <Work/>
    <Team/>
    <OurPartners/>
    <div ref={contactus} id="contactus">
    <Route path={["/contact","/"]}>
    <Footer aboutus={aboutus} contactus={contactus} />
    </Route>    
    </div>
    <Scroll aboutus={aboutus} contactus={contactus} />
    </div>
    </Switch>
  );
}

export default App;

