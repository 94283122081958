import React,{useEffect} from "react";
import {FaTruckMoving, FaDollarSign} from "react-icons/fa";
import {BsFillTelephoneFill, BsFillBagFill, BsFillHouseDoorFill} from "react-icons/bs";
// import {HiSpeakerphone} from "react-icons/hi";
import {MdFastfood} from "react-icons/md";
import styled from "styled-components";

import AOS from "aos";
import "aos/dist/aos.css";

const HighVolume=()=>{
    useEffect(() => {
        AOS.init({
          duration : 8000
        });
      }, []);
    return (
        <Volume>
            <small><u>SERVICE</u></small>
            <div className="text">
            <h1>For High Volume Industries</h1>
            </div>
            <div className="containers">
                <div data-aos="fade-up" data-aos-duration={"800"} data-aos-delay={"0"} data-aos-anchor-placement="top-bottom" className="container">
                    <BsFillTelephoneFill className="icons"/>
                    <div className="main ind-title"><h3>Contact Center</h3></div>
                    {/* <div className="desc">Digital Soutions Experience is repudandae fugia rchitecto beatea rederit.</div> */}
                </div>


                <div data-aos="fade-up" data-aos-duration={"800"} data-aos-delay={"100"} data-aos-anchor-placement="top-bottom" className="container">
                    <BsFillBagFill className="icons"/>
                    <div className="main ind-title"><h3>Retails</h3></div>
                    {/* <div className="desc">Digital Soutions Experience is repudandae fugia rchitecto beatea rederit.</div> */}
                </div>

                <div data-aos="fade-up" data-aos-duration={"800"} data-aos-delay={"200"} data-aos-anchor-placement="top-bottom" className="container">
                    <FaTruckMoving className="icons"/>
                    <div className="main ind-title"><h3>Logistics</h3></div>
                    {/* <div className="desc">Digital Soutions Experience is repudandae fugia rchitecto beatea rederit.</div> */}
                </div>

                <div data-aos="fade-up" data-aos-duration={"800"} data-aos-delay={"300"} data-aos-anchor-placement="top-bottom" className="container">
                    <FaDollarSign className="icons"/>
                    <div className="main ind-title"><h3>Sales</h3></div>
                    {/* <div className="desc">Digital Soutions Experience is repudandae fugia rchitecto beatea rederit.</div> */}
                </div>

                <div data-aos="fade-up" data-aos-duration={"800"} data-aos-delay={"400"} data-aos-anchor-placement="top-bottom" className="container">
                    <BsFillHouseDoorFill className="icons"/>
                    <div className="main ind-title"><h3>Warehouses</h3></div>
                    {/* <div className="desc">Digital Soutions Experience is repudandae fugia rchitecto beatea rederit.</div> */}
                </div>

                <div data-aos="fade-up" data-aos-duration={"800"} data-aos-delay={"500"} data-aos-anchor-placement="top-bottom" className="container">
                    <MdFastfood className="icons"/>
                    <div className="main ind-title"><h3>Food Services</h3></div>
                    {/* <div className="desc">Digital Soutions Experience is repudandae fugia rchitecto beatea rederit.</div> */}
                </div>
            </div>
            <small><u>Join us</u></small>

        </Volume>
    )
}

const Volume=styled.div`
    margin: 2rem 0rem;
    display: flex;;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .ind-title{
        margin-top:10px;
    }
    h1{
        margin-top: 1rem;
    }
    .containers{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }
    .container{
        display: flex;;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width:24rem;
        margin: 1rem;
        text-align: center;
        box-sizing: border-box;
        padding: 2rem 2rem;
        cursor: pointer;
        transition: 0.4s ease;
        &:hover{
            box-shadow: 8px 10px 20px -2px #D3D3D3;
            transform: scale(1.1);
        }
    .icons{
        font-size: 3.8rem;
        height:40px;
        width:40px;
    };

    }
    @media (max-width:650px){
        h1{
            margin-left:5%;
            /* algin-text:center; */
        }
    }
`
export default HighVolume;