import React,{useEffect} from "react";
import { Link } from "react-router-dom";
import "../Nav.css";
import logo from "../images/logo.png";
import AOS from "aos";
import "aos/dist/aos.css";


export default function Nav({aboutus,contactus}) {
    useEffect(() => {
        AOS.init({
          duration : 2000
        });
      }, [])
  const [click, setClick] = React.useState(false);

  const handleClick = () =>{
    setClick(!click);
  }
  const Close = () => setClick(false);
  const scrollToAbout=()=>{
    aboutus.current.scrollIntoView({behavior: "smooth"});

  }
  
  return (
    <div data-aos={"fade-down"} data-aos-delay={"800"} data-aos-duration={"800"}>
     <div  className={click ? "main-container" : ""} onClick={()=>Close()} />
      <nav className="navbar" onClick={(e)=>e.stopPropagation()}>
        <div className="nav-container">
          <Link exact to="/" className="nav-logo">
          <img src={logo} alt="" style={{"margin-right":"15px", "height":"50px", "width":"50px"}} />
            <div>Keep Hiring</div>
          </Link>

          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <Link
                exact
                to="/"
                activeClassName="active"
                className="nav-links"
                onClick={click ? handleClick : null}
              >
                Home
              </Link>
            </li>
            <li onClick={scrollToAbout} className="nav-item">
              <Link
                exact
                to="/about"
                activeClassName="active"
                className="nav-links"
                onClick={click ? handleClick : null}
                
                
              >
                About
              </Link>
            </li>
            
            <li onClick={()=>contactus.current.scrollIntoView({behavior: "smooth"})} className="nav-item">
              <Link
                exact
                to="/contact"
                activeClassName="active"
                className="nav-links"
               onClick={click ? handleClick : null}
              >
                Contact Us
              </Link>
            </li>

            <li className="nav-item">
              <a
                exact
                href="mailto:career@keephiring.co"
                activeClassName="active"
                className="nav-links"
               onClick={click ? handleClick : null}
              >
                Start Hiring
              </a>
            </li>

          </ul>
          <div className="nav-icon" onClick={handleClick}>
            <i className={click ? "fa fa-times" : "fa fa-bars"}></i>
          </div>
        </div>
      </nav>
    </ div>
  );
}
