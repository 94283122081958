import React,{useEffect} from "react";
import alibaba from "../images/alibaba.png";
import amazon from "../images/amazon.png";
import dropbox from "../images/dropbox.png";
import pinterest from "../images/pinterest.png";
import spacex from "../images/spacex.png";
import AOS from "aos";
import "aos/dist/aos.css";

import { Link } from "react-router-dom";

import styled from "styled-components";

const OurPartners=()=>{
    useEffect(() => {
        AOS.init({
          duration : 2000
        });
      }, []);
    return(
    <SliderStyle>
        <h1> Our Partners</h1>
        <div className="main">
            <div className="slide"> <img src={alibaba} alt="" /></div>
            <div className="slide"> <img src={pinterest} alt="" /></div>
            <div className="slide"> <img src={dropbox} alt="" /></div>
            <div className="slide"> <img src={amazon} alt="" /></div>
            <div className="slide"> <img src={spacex} alt="" /></div>

        </div>
        <Link data-aos={"fade-up"} data-aos-duration={"800"} data-aos-delay={"100"} to= "/">Meet Our Partners</Link>
    </SliderStyle>
    );
}

const SliderStyle=styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
margin-bottom: 4rem;
h1{
    text-align: center;
    margin: 2rem;
}
a{
        background-color: black;
        text-decoration: none;
        border-radius: 10px;
        padding: 1.4rem;
        color: white;
        box-shadow: 0px 2px 4px gray;
        transition: 0.4s;
        &:hover{
            background-color: #383431;
            color: white;
        }

    }
.main{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    margin-bottom: 4rem;
    box-shadow: 0px 10px 20px #d3d3d3;
    margin: 0rem 1rem 4rem 1rem;
    
}
.slide{
    padding:0rem 2rem;
}
@media (max-width:810px){
        .slide{
            
            padding:0;
            img{
                width: 70px;
            }
        }
    }
    @media (max-width:400px){
        .slide{
            
            padding:0;
            img{
                width: 50px;
            }
        }
    }
`
export default OurPartners;