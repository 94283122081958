import React,{useEffect} from "react";
import styled from "styled-components";
import AOS from "aos";
import "aos/dist/aos.css";
import work from "../images/exp.jpeg";

const Work=()=>{
    useEffect(() => {
        AOS.init({
          duration : 2000
        });
      }, []);
    return(
        <HeaderStyle>
            
            <div data-aos-anchor-placement={"top-bottom"} data-aos={"fade-up"} data-aos-duration={"1000"}  className="left">
                <div className="hero-text">
                <h1>What have we done</h1>
                <p>We don’t just speak, we do it. We’ve helped serveral organizations to hire some of the best minds in the world.</p>
                </div>
                <div className="down">
                    <div className="container">
                        <h2>534</h2>
                        <div className="text">Completed Projects</div>
                    </div>
                    <div className="container">
                        <h2>534</h2>
                        <div className="text">Completed Projects</div>
                    </div>
                    <div className="container">
                        <h2>534</h2>
                        <div className="text">Completed Projects</div>
                    </div>
                    <div className="container">
                        <h2>534</h2>
                        <div className="text">Completed Projects</div>
                    </div>
                </div>
                  
            </div>
            <div data-aos-delay={"100"} data-aos={"fade-up"} data-aos-duration={"1000"} className="right">
                <img src={work} alt="" />
            </div>
            
        </HeaderStyle>
    );
}
const HeaderStyle=styled.div`
    width:100%;
    height: auto;
    display: flex;
    padding: 10rem;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    .left{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-left: 2rem;
        width:70%;
        *{
            margin: 1rem 0rem;
        }
        h2{
            margin-bottom: 1rem;
            align-self: center;
        }
        a{
            text-decoration: none;
            color: white;
            background-color: black;
            margin-top:2rem ;
        }
    }
    .hero-text{
        
        font-size: 1rem;
        color:black;
        margin: -2rem 0rem 2rem 0rem;
        width:50%;
    }
    a{
        background-color: white;
        text-decoration: none;
        font-size: 25px;
        border-radius: 10px;
        padding: 0.8rem;
        color: black;
        box-shadow: 0px 2px 4px gray;
        transition: 0.4s;
        &:hover{
            background-color: #383431;
            color: white;
        }

    }
    .right{
        margin: 0rem 4rem;
        img{
            position: relative;
        margin-top: -2rem;
        width: 22rem;
        margin-left: 0rem;
        height: 50vh;
        &::before{
            content: "";
            position: absolute;
            background-color: black;
            width: 20rem;
            height: 20vh;
        }
        @media (max-width:700px){
            margin-top: 0rem;
            height: 30rem; 
        }
        }
    }
    .down{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 4rem;
       .container{
           justify-content: center;
           text-align: center;
           flex-direction: column;
           align-self: center;
            background:#fbf5eb;
           border-radius:1rem;
           margin: 0.4rem;
           width:8rem;
           height: 6rem;
           line-height: 1rem;
           padding: 0.4rem;
       }
    }
    
    @media (max-width:810px){
        flex-wrap:wrap;
        padding: 3rem;
        margin-top: 2rem;
        .left{
            flex-direction:column;
            justify-content: center;
            align-items: center;
            .down{
                flex-wrap: wrap;
            }
        }
        .hero-text,.left,.right{
            width: 100%;
        }
        .down{
            margin-left: -2rem;
        }
        .right{
            margin: 0;
            margin-left: 2.4rem;
            img{
                width:18rem;
                height: 24rem;
            }
        }
    }
    @media (max-width:650px){
        .right{
            img{
                width:18rem;
                height:12rem;
                margin-left: -2rem;
                margin-bottom:2rem;
            }
        }
        .left{
            margin-left: -0.4rem;
        }
    }
`

export default Work;