import React,{useEffect} from "react";
import styled from "styled-components";
import Hero from "../images/Hero.png";
import AOS from "aos";
import "aos/dist/aos.css";
import {FaUserAlt} from "react-icons/fa";
import {BiSupport} from "react-icons/bi";



const Mission=()=>{
    
    useEffect(() => {
        AOS.init({
          duration : 2000
        });
       
      }, []);

   

    return(
        <div id="aboutus">
        <HeaderStyle>
            <div data-aos-delay={"100"} data-aos={"fade-up"} data-aos-duration={"1000"} className="right">
                <img src={Hero} alt="" />
            </div>
            <div data-aos={"fade-up"} data-aos-anchor-placement={"top-bottom"}  data-aos-duration={"1000"}  className="left">
                <div className="hero-text">
            <small><u>About Us</u></small>

                <h1>Keep Hiring and our mission</h1>
                <div className="down">“Keep Hiring” AI system reach-out to active and qualified candidates to apply directly on your “job link” or landing page and delivers interviews with qualified candidates.</div>
                </div>
                <div data-aos="fade-up"
     data-aos-anchor-placement="top-bottom" data-aos-duration='800' data-aos-delay="100" className="container exp">
                    <FaUserAlt  className="icon"/>
                    <div className="text ">
                        <h4>Experience</h4>
                        <div className="downcon">Our team members have worked with most successful organizations and know what brings success.</div>
                    </div>
                </div>
                <div data-aos="fade-up"
     data-aos-anchor-placement="top-bottom" data-aos-duration='800' data-aos-delay="200" className="container">
                    <BiSupport className="icon"/>
                    <div className="text">
                        <h4>Quick Support</h4>
                        <div className="downcon">We believe that support throughout should be excellent. We make sure to fullfil this!</div>
                    </div>
                </div>
            </div>
            
        </HeaderStyle>
        </div>
    );
}
const HeaderStyle=styled.div`
    background:#fbf5eb;
    width:100%;
    height: auto;
    padding: 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 1rem;
    flex-wrap: wrap;
    padding-top: 8rem;
    padding-bottom: 8rem;
    .left{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-left: 2rem;
        width:45rem;
       
        h1{
            margin-bottom: 2rem;
            margin-top: 1rem;
        }
        .down{
            margin-top: 2rem;
            line-height: 2rem;
        }
    }
    .hero-text{
        
        font-size: 1rem;
        color:black;
        margin: -2rem 0rem 4rem 0rem;
        width:80%;
    }
    a{
        background-color: white;
        text-decoration: none;
        font-size: 25px;
        border-radius: 10px;
        padding: 0.8rem;
        color: black;
        box-shadow: 0px 2px 4px gray;
        transition: 0.4s;
        &:hover{
            background-color: #383431;
            color: white;
        }

    }
    .right{
            position: relative;
        img{
        margin-top: -2rem;
        width: 25rem;
        height: 70vh;
        margin-left: 4rem;
        height: 80vh;
        @media (max-width:700px){
            margin-top: -2rem;
            height: 82vh;
            margin-left: 8rem;
        }
        }
        &::before{
            content: "";
            position: absolute;
            width:30rem;
            height:20rem;
            top:40%;
            left:8%;
            background-color: #FEEDCE;
            z-index: -1;
            border-radius: 3rem;
            @media (max-width:700px){
            width: 20rem;
            height: 12rem
        }
        }
    }
    .container{
        display: flex;
        justify-content:center;
        align-items: center;
        margin-bottom: 1rem;
        width:60%;
        background-color: white;
        padding: 0.8rem;
        border-radius: 3rem;
        h4{
            margin: 0.8rem 0rem;
        }
            .icon{
                font-size: 5rem;
                margin-right: 0.8rem;
            }
        
    }
    
    @media (max-width:650px){
        .right{
            margin-left: -8rem;
            img{
                width:16rem;
                margin-left: -1rem;
            }
        }
        .left{
            margin-left: -2rem;
        }
    }
    @media (max-width:780px){
        .right{
           margin-left: 1rem;
            img{
                margin-left:2rem;
                width:16rem;
                height: 22rem;
                margin-bottom: 2rem;
            }
    
        }
        .left{
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 2.8rem;
        }
        .container{
            width: 80%;
            margin-left: 0.4rem;
        }
    }
   
`

export default Mission;