import React,{useEffect} from "react";
import { useLocation } from "react-router";
const Scroll=({aboutus,contactus})=>{
    const location=useLocation();
    const path=location.pathname;
    useEffect(()=>{
        if(path==="/about"){
            aboutus.current.scrollIntoView({behavior: "smooth"});
        }
        else if(path==="/contact"){
            contactus.current.scrollIntoView({behavior: "smooth"});
        }
        console.log(path)
    },[path,aboutus,contactus])
    return (
        <>
        </>
    )
    
}
export default Scroll;